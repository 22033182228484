#MFooter{
    font-size: .2rem;
    font-family: "NTLight";
    height:220px;
    background: #222;
    color: #fff;
}

#MFooter .footer_contents{
    width: 100%;
    height:100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 30px;
    position: relative;
    z-index: 1;
}

#MFooter .footer_contents .footer_info{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    line-height: 1.5;
    padding: 20px 0;
}

#MFooter .footer_contents .footer_info .copyright{
    opacity: .5;
}

#MFooter .footer_contents .logo{
    display: inline-block;
    width: 150px;
}

#MFooter .footer_contents .logo > img{
    width: 100%;
    height: auto;
}

#MFooter .footer_contents .back_logo{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    top: 0.5rem;
    right: 30px;
    width: 120px;
    height: 150px;
    z-index: -1;
}
