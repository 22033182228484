#MHome {
  font-size: 0.7rem;
  font-family: "NTLight";
  height: 100%;
  margin: 0 auto;
  position: relative;
}

#MHome .home_contents {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 15;
}

/* Partner */
#MHome #MPartner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 3rem;
  padding: 50px 0;
  box-sizing: border-box;
}

#MHome #MPartner .partner_contents {
  display: flex;
  align-items: center;
  justify-content: center;
}

#MHome #MPartner div > img {
  width: 60%;
  height: 35%;
}

/* Company */
#MHome #MCompany {
  display: flex;
  flex-direction: column;
  padding-top: 0.5rem;
  font-family: "NTLight";
}

#MHome #MCompany .company_contents {
  display: flex;
  padding: 30px;
  box-sizing: border-box;
  position: relative;
}

#MHome #MCompany .company_info {
  font-size: 0.22rem;
  width: 100%;
}

#MHome #MCompany .company_info .title {
  font-size: 0.3rem;
  font-family: "NTBold" !important;
}

#MHome #MCompany .company_info .title_info {
  padding: 0.4rem 0 1rem 0;
  line-height: 1.5;
  font-size: 0.22rem;
}

#MHome #MCompany .company_name {
  writing-mode: vertical-lr;
  font-size: 0.8rem;
  opacity: 0.1;
}

#MHome #MCompany .figure_1 {
  position: absolute;
  right: 0;
  top: -1.2rem;
  z-index: -5;
  width: 300px;
}

/* Member */
#MHome #MMember {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 5rem;
  padding: 0.2rem 0;
  box-sizing: border-box;
  background: #fff;
}

#MHome #MMember .member_title {
  font-size: 0.3rem;
  text-align: center;
}

#MHome #MMember .member_title div:nth-child(2) {
  font-family: "NTBold";
  padding: 0.1rem 0;
}

#MHome #MMember .member_img {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#MHome #MMember .member_img > img {
  width: 65%;
}

/* Business */
#MHome #MBusiness {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 1rem 0;
  font-size: 0.18rem;
}

#MHome #MBusiness .business_contents {
  padding: 30px;
  box-sizing: border-box;
}

#MHome #MBusiness .business_contents .business_img {
  width: 69.734%;
}

#MHome #MBusiness .business_contents .title {
  padding: 20px 0;
}

#MHome #MBusiness .business_contents .title > div {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #000;
  display: inline-block;
  max-width: 2rem;
  padding: 0.08rem 0.15rem;
  font-size: 0.22rem;
}

#MHome #MBusiness .business_contents > div:nth-child(3) {
  position: relative;
}

#MHome #MBusiness .business_contents .title_info {
  line-height: 1.5;
  padding-bottom: 1rem;
}

#MHome #MBusiness .business_contents .title_info > div {
  font-family: "NTBold";
  padding-bottom: 0.1rem;
}

#MHome #MBusiness .business_contents .figure_2 {
  position: absolute;
  left: -5.5rem;
  top: -3.2rem;
  z-index: -1;
}

#MHome #MBusiness .business_contents .figure_3 {
  position: absolute;
  right: -1rem;
  top: -2.5rem;
  width: 330px;
  z-index: -1;
}

/* Project */
#MHome #MProject {
  display: flex;
  flex-direction: column;
  background: #212121;
  color: #fff;
  padding: 50px 30px;
  box-sizing: border-box;
}

#MHome #MProject .project_title {
  padding-bottom: 50px;
}

#MHome #MProject .slide {
  width: 100%;
  z-index: 15;
}

/* Media */
#MHome #MMedia {
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  font-size: 0.16rem;
  font-family: "NTLight";
  position: relative;
}

#MHome #MMedia .media_contents {
  width: 100%;
  padding: 0.8rem 0;
  position: relative;
  padding: 30px;
}

#MHome #MMedia .media_contents .figure_4 {
  position: absolute;
  left: 1rem;
  top: 4rem;
  z-index: -5;
  width: 2.5rem;
  height: 2.5rem;
}

#MHome #MMedia .figure_5 {
  position: absolute;
  bottom: 3rem;
  right: -4rem;
  z-index: -5;
}

#MHome #MMedia .media_contents > div {
  height: 25%;
  position: relative;
  display: flex;
  padding: 10px 0;
}

#MHome #MMedia .media_contents > div:nth-child(even) {
  justify-content: flex-start;
}

#MHome #MMedia .media_contents > div:nth-child(odd) {
  justify-content: flex-end;
}

#MHome #MMedia .media_contents .contents {
  height: 100%;
  width: 75%;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

#MHome #MMedia .media_contents .contents .title {
  font-size: 0.3rem;
  font-family: "NTBold";
  overflow: hidden;
  color: #000;
}

#MHome #MMedia .media_contents .contents .text {
  width: 45.666%;
  padding: 45px 0;
  font-size: 0.16rem;
}

#MHome #MMedia .media_contents .img {
  display: flex;
  position: absolute;
  right: 0;
  margin: auto;
  z-index: -1;
  width: 49%;
  background-image: linear-gradient(to bottom, rgb(100, 182, 240) 15%, rgb(81, 155, 244));
}

/* Location */
#MHome #MLocation {
  display: flex;
  flex-direction: column;
  height: 7rem;
  font-family: "NTLight";
  font-size: 0.2rem;
}

#MHome #MLocation .location_contents {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 30px;
}

#MHome #MLocation .location_contents .adress {
  padding: 20px 0;
  box-sizing: border-box;
}
#MHome #MLocation .location_contents .adress > div:nth-child(1) {
  font-family: "NTBold";
  font-size: 0.25rem;
}

/* contact */
#MHome #MContact {
  display: flex;
  flex-direction: column;
  font-family: "NTBold";
  font-size: 0.18rem;
  padding: 1rem 0;
}

#MHome #MContact .contact_conntents {
  width: 100%;
}

#MHome #MContact .contact_conntents .contact_form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
}

#MHome #MContact .contact_conntents .contact_form > div {
  width: 100%;
}

#MHome #MContact .contact_conntents .contact_form > div:nth-child(odd) {
  padding: 10px 0;
}

#MHome #MContact .contact_conntents .contact_form .input_area > input,
#MHome #MContact .contact_conntents .contact_form .input_area > textarea {
  border: 1px solid #bfbfbf;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  font-family: "NTLight";
}

#MHome #MContact .contact_conntents .contact_form .input_area > textarea {
  min-height: 100px;
}

#MHome #MContact .contact_conntents .agree {
  display: flex;
  align-items: center;
  padding: 10px 0 20px 0;
  font-family: "NTMedium";
  font-size: 0.15rem;
  text-decoration: underline;
}

/* common */
#MHome .B_title > div,
#MHome .L_title > div {
  position: relative;
  display: inline-block;
  padding: 0 10px;
}

#MHome .btn_send {
  height: 0.5rem;
  border-radius: 30px;
  font-size: 0.12rem;
  background: #17e4bd;
  color: #fff;
  font-family: "NTBold";
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

#MHome .line_more {
  text-decoration: underline;
  color: #17e4bd;
  font-family: "NTMedium";
  cursor: pointer;
}

#MHome .top_go {
  position: fixed;
  bottom: 25px;
  right: 25px;
  width: 50px;
  height: 50px;
  cursor: pointer;
  z-index: 1000000;
  display: none;
}

#MHome .active {
  display: block !important;
}

@media screen and (max-width: 768px) {
  #MHome #MPartner {
    height: 3rem;
    /* padding: 30px 0; */
  }

  #MHome .btn_send {
    height: 0.8rem;
  }

  #MHome .btn_more {
    width: 1rem;
    height: 0.5rem;
    top: 0;
    right: -1.7rem;
    z-index: 5;
  }
}
