#MMain {
  font-size: 0.7rem;
  width: 100%;
  z-index: -1;
  font-family: "NTThin";
  height: 100%;
  margin-top: 50px;
}

#MMain .main_content {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: absolute;
  padding: 0.8rem 0;
  z-index: 10;
  top: 0;
}

#MMain .main_content .main_title_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #fff;
  font-size: 0.25rem;
}

#MMain .main_content .main_title_wrap .title_top {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* height: 70%; */
}

#MMain .main_content .main_title_wrap .title_top::before {
  width: 10px;
  content: "";
  background-image: url(/img/asset/left-quote.png);
  position: absolute;
  left: 4%;
  top: 0;
  height: 10px;
  background-size: 100%;
  background-repeat: no-repeat;
}

#MMain .main_content .main_title_wrap .title_top::after {
  width: 10px;
  content: "";
  background-image: url(/img/asset/right-quote.png);
  position: absolute;
  right: 4%;
  top: 0;
  height: 10px;
  background-size: 100%;
  background-repeat: no-repeat;
}

#MMain .main_content .main_title_wrap .title_top div:nth-child(2) {
  font-family: "NTBold";
  font-size: 0.28rem;
  padding: 0.45rem 0 0.7rem 0;
}

#MMain .main_content .main_title_wrap .title_top .box_line {
  border: 5px solid #fff;
  width: 1.8rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

#MMain .main_content .main_title_wrap .title_top .box_line > img {
  width: 90%;
  height: 90%;
}

#MMain .main_content .main_title_wrap .title_bottom {
  height: 20%;
  font-family: "NTLight";
  font-size: 0.18rem;
  opacity: 0.5;
  line-height: 4.5;
}

#MMain .swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

#MMain .swiper-slide {
  width: 100% !important;
}

@media screen and (max-width: 768px) {
  /* #MMain{
        height: 70vh;
    } */

  #MMain .main_content .main_title_wrap .title_top .box_line {
    height: 0.5rem;
    border: 2px solid #fff;
  }

  #MMain .swiper-container {
    height: 60vh;
  }
}
