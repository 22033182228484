#Footer {
  font-size: 0.15rem;
  font-family: "NTLight";
  height: 220px;
  position: relative;
  background: #222;
  color: #fff;
  padding: 50px 0;
}

#Footer .footer_contents {
  width: calc(100% - 125px);
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

#Footer .footer_contents .logo {
  display: inline-block;
}

#Footer .footer_contents .logo > img {
  width: 100%;
  height: auto;
}

#Footer .footer_contents .footer_info {
  width: 66.668%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 1.5;
  padding: 0 1rem;
}

#Footer .footer_contents .footer_info .copyright {
  opacity: 0.5;
}

#Footer .footer_contents .small_logo {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

#Footer .footer_contents .small_logo > div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0.85rem;
  height: 0.85rem;
}

.row {
  width: 16.667%;
}
