#Grid{
    display: inline-block;
    white-space: nowrap;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    z-index: 10;
}
  
#Grid .line{
    display: inline-block;
    height: 100%;
    border-right: 1px solid #d7d7d7;
    box-sizing: border-box;
    opacity: 0.2;
}

#Grid div:nth-child(6){
    border:none
}

.row {
    width: 16.667%;
}