/* @font-face {
  font-family: "TTFLight";
  src: url("/font/GmarketSansTTFLight.ttf") format("opentype");
}

@font-face {
  font-family: "TTFMedium";
  src: url("/font/GmarketSansTTFMedium.ttf") format("opentype");
}

@font-face {
  font-family: "TTFBold";
  src: url("/font/GmarketSansTTFBold.ttf") format("opentype");
} */

#App {
  width: 100%;
  height: 100%;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#App .on_web {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  background: #ededed;
  transition: visibility 3s linear 3.33s, opacity 3.33s linear;
}

#App .on_mobile {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  background: #ededed;
}
