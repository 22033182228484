/* #Carousel{
  box-sizing: border-box;
}

#Carousel .main-ticker-wrap {
  overflow: hidden;
}

#Carousel .main-ticker-body {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

#Carousel .item {
  flex-shrink: 0;
  white-space: nowrap;
  max-width: 20%;
  min-width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes ticker-kf {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(-100%, 0, 0);
  }
}

#Carousel .main-ticker-body {
  animation: ticker-kf 15s linear infinite;
} */

#Carousel {
  box-sizing: border-box;
}

.flow_container {
  position: relative;

  overflow: hidden;
}

.flow_container::before {
  bottom: 0;
  content: "";
  position: absolute;
  top: 0;
  width: 1rem;
  z-index: 10;
  box-shadow: inset 48px 0 48px 5px #ededed;
  left: 0;
}

.flow_container::after {
  bottom: 0;
  content: "";
  position: absolute;
  top: 0;
  width: 1rem;
  z-index: 10;
  box-shadow: inset -48px 0 48px 5px #ededed;
  right: 0;
}

.flow {
  display: inline-flex;
  background-color: #ededed;
  animation: flowlogo 15s linear infinite;
  /* transform: translateZ(0); */
  /* will-change: transform; */
}

.flow:hover {
  animation-play-state: paused;
}

.flex {
  display: inline-flex;
}

.items-end {
  align-items: flex-end;
  box-align: end;
  background-color: #ededed;
}

.flow .logo {
  margin-right: 60px;
  filter: grayscale(1);
}

.flow .logo:hover {
  filter: grayscale(0);
}

.flow .logo img {
  display: block;
  max-width: none;
  width: auto;
  height: 60px;
}

@keyframes flowlogo {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-50%, 0, 0);
  }
}

@media screen and (max-width: 768px) {
  #Carousel {
    width: 100%;
    margin-top: 20px;
    box-sizing: border-box;
  }

  .flow .logo img {
    height: 30px;
  }
}
