#Load {
  width: 100%;
  height: 100vh;
  background: #060a11;
}

#Load .load_wrap {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#Load .logo_wrap {
  position: relative;
  width: 350px;
  padding: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#Load .logo_wrap > img {
  width: 100%;
  height: 100%;
}

@keyframes border1 {
  0% {
    width: 0;
    height: 0;
    border-top-color: #ffffff;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }

  50% {
    width: 100%;
    height: 0;
    border-top-color: #ffffff;
    border-right-color: #ffffff;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }

  100% {
    width: 100%;
    height: 100%;
    border-top-color: #ffffff;
    border-right-color: #ffffff;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
}

@keyframes border2 {
  0% {
    width: 0;
    height: 0;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: #ffffff;
  }

  50% {
    width: 0;
    height: 100%;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: #ffffff;
    border-left-color: #ffffff;
  }

  100% {
    width: 100%;
    height: 100%;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: #ffffff;
    border-left-color: #ffffff;
  }
}

#Load .logo_wrap:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 0;
  height: 0;
  background: transparent;
  border: 4px solid transparent;
  animation: border1 0.6s linear forwards;
  box-sizing: border-box;
}

#Load .logo_wrap::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 0;
  height: 0;
  background: transparent;
  border: 4px solid transparent;
  animation: border2 0.6s linear forwards;
  box-sizing: border-box;
}

@media screen and (max-width: 768px) {
  #Load .logo_wrap {
    width: 220px;
    padding: 25px;
  }
}
