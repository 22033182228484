#Home {
  width: calc(100% - 125px);
  font-size: 0.7rem;
  font-family: "NTLight";
  height: 100%;
  margin: 0 auto;
  position: relative;
}

#Home .home_contents {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 15;
}

#Home #Grid .line {
  opacity: 1;
}

/* Partner */
#Home #Partner {
  display: flex;
  align-items: center;
  height: 3rem;
}

#Home #Partner div > img {
  width: 65%;
  height: 50%;
}

#Home #Partner .partner_contents {
  width: 83.335%;
}

/* Company */
#Home #Company {
  display: flex;
  padding-top: 1rem;
  font-family: "NTLight";
  position: relative;
}

#Home #Company .company_name {
  writing-mode: vertical-lr;
  font-size: 0.8rem;
  opacity: 0.1;
}

#Home #Company .company_contents {
  position: relative;
  font-size: 0.22rem;
  width: 50.001%;
}

#Home #Company .company_contents .title {
  font-size: 0.3rem;
  font-family: "NTBold" !important;
}

#Home #Company .company_contents .title_info {
  padding: 0.4rem 0 1rem 0;
  line-height: 1.5;
  font-size: 0.22rem;
}

#Home #Company .company_contents .figure_1 {
  position: absolute;
  right: 0;
  top: -2.5rem;
  z-index: -5;
}

/* Member */
#Home #Member {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 7.9rem;
  padding: 0.2rem 0;
  box-sizing: border-box;
  background: #fff;
  position: relative;
}

#Home #Member .member_title {
  font-size: 0.3rem;
  text-align: center;
}

#Home #Member .member_title div:nth-child(2) {
  font-family: "NTBold";
  padding: 0.1rem 0;
}

#Home #Member .member_img {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#Home #Member .member_img > img {
  width: 65%;
}

#Home #Member #Right {
  border-left: 1px solid #fff;
  position: absolute;
  right: -60px;
  background: #fff;
}

#Home #Member #Left {
  border-right: 1px solid #fff;
  position: absolute;
  left: -60px;
  background: #fff;
}

/* Business */
#Home #Business {
  display: flex;
  position: relative;
  padding: 2rem 0;
  font-size: 0.18rem;
}

#Home #Business .business_contents {
  max-width: 50%;
}

#Home #Business .business_contents .business_img {
  width: 69.734%;
}

#Home #Business .business_contents .title {
  padding: 40px 0;
}

#Home #Business .business_contents .title > div {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #000;
  display: inline-block;
  max-width: 2rem;
  padding: 0.08rem 0.15rem;
  font-size: 0.22rem;
}

#Home #Business .business_contents .title_info {
  line-height: 1.5;
  padding-bottom: 0.6rem;
}

#Home #Business .business_contents .title_info > div {
  font-family: "NTBold";
  padding-bottom: 0.1rem;
}

#Home #Business .business_contents > div:nth-child(3) {
  position: relative;
}

#Home #Business .business_contents .figure_2 {
  position: absolute;
  left: -2.5rem;
  top: -1.2rem;
  z-index: 10;
}

#Home #Business .business_contents .figure_3 {
  position: absolute;
  right: 0rem;
  top: -2.5rem;
  z-index: -5;
}

/* Project */
#Home #Project {
  display: flex;
  height: 10.35rem;
  background: #212121;
  color: #fff;
  position: relative;
}

#Home #Project .project_title {
  padding: 0.9rem 0;
  position: absolute;
}

#Home #Project .slide {
  width: 100%;
  z-index: 15;
}

#Home #Project #Grid .line {
  border-right: 1px solid #383838;
  border-left: 1px solid #383838;
  opacity: 0.5;
}

#Home #Project #Right {
  position: absolute;
  right: -60px;
  background: #212121;
  border-left: 1px solid #212121;
}

#Home #Project #Left {
  border-right: 1px solid #212121;
  position: absolute;
  left: -60px;
  background: #212121;
}

/* Media */
#Home #Media {
  display: flex;
  height: 21.2rem;
  padding: 1.5rem 0;
  font-size: 0.16rem;
  font-family: "NTLight";
  position: relative;
}

#Home #Media .media_contents {
  width: 66.666%;
  padding: 0.8rem 0;
  position: relative;
}

#Home #Media .media_contents .figure_4 {
  position: absolute;
  left: 1rem;
  top: 4rem;
  z-index: -5;
  width: 2.5rem;
  height: 2.5rem;
}

#Home #Media .figure_5 {
  position: absolute;
  bottom: 3rem;
  right: -4rem;
  z-index: -5;
}

#Home #Media .media_contents > div {
  height: 25%;
  position: relative;
  display: flex;
}

#Home #Media .media_contents > div:nth-child(even) {
  justify-content: flex-start;
}

#Home #Media .media_contents > div:nth-child(odd) {
  justify-content: flex-end;
}

#Home #Media .media_contents .contents {
  height: 100%;
  width: 75%;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

#Home #Media .media_contents .contents .title {
  font-size: 0.3rem;
  font-family: "NTBold";
  overflow: hidden;
  color: #000;
}

#Home #Media .media_contents .contents .text {
  width: 45.666%;
  padding: 45px 0;
  font-size: 0.16rem;
}

#Home #Media .media_contents .img {
  display: flex;
  position: absolute;
  top: 0.75rem;
  right: 0;
  margin: auto;
  z-index: -1;
  width: 49%;
  background-image: linear-gradient(to bottom, rgb(100, 182, 240) 15%, rgb(81, 155, 244));
}

/* Location */
#Home #Location {
  display: flex;
  height: 7.75rem;
  font-family: "NTLight";
  font-size: 0.18rem;
}

#Home #Location .location_contents {
  width: 83.335%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

#Home #Location .location_contents .adress > span {
  font-family: "NTBold";
}

/* contact */
#Home #Contact {
  display: flex;
  height: 6.8rem;
  font-family: "NTBold";
  font-size: 0.18rem;
  padding: 1.3rem 0;
}

#Home #Contact .contact_conntents {
  width: 50.001%;
}

#Home #Contact .contact_conntents .contact_form {
  width: 100%;
}

#Home #Contact .contact_conntents .contact_form > div:nth-child(odd) {
  padding: 10px 0;
}

#Home #Contact .contact_conntents .contact_form .input_area > input,
#Home #Contact .contact_conntents .contact_form .input_area > textarea {
  border: 1px solid #bfbfbf;
  width: 66.666%;
  box-sizing: border-box;
  padding: 10px;
  font-family: "NTLight";
}

#Home #Contact .contact_conntents .contact_form .input_area > textarea {
  min-height: 100px;
}

#Home #Contact .contact_conntents .agree {
  display: flex;
  align-items: center;
  padding: 10px 0 20px 0;
  font-family: "NTMedium";
  font-size: 0.15rem;
  text-decoration: underline;
}

/* common */
.B_title {
  font-family: "NTBold";
  font-size: 0.4rem;
}

.L_title {
  font-family: "NTLight";
  font-size: 0.4rem;
}

.B_title > div,
.L_title > div {
  position: relative;
  display: inline-block;
}

.b_square,
.g_square {
  border: 1px solid #42bffa;
  background: #42bffa;
  width: 12px;
  height: 12px;
  display: inline-block;
  position: absolute;
  right: -22px;
  bottom: 0;
}

.g_square {
  border: 1px solid #17e4bd;
  background: #17e4bd;
}

.btn_more {
  width: 0.85rem;
  height: 0.35rem;
  border-radius: 30px;
  font-size: 0.12rem;
  background: #17e4bd;
  color: #fff;
  font-family: "NTBold";
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: -1.25rem;
  z-index: 5;
}

.btn_send {
  width: 0.85rem;
  height: 0.35rem;
  border-radius: 30px;
  font-size: 0.12rem;
  background: #17e4bd;
  color: #fff;
  font-family: "NTBold";
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.line_more {
  text-decoration: underline;
  color: #17e4bd;
  font-family: "NTMedium";
  cursor: pointer;
}

#Home .top_go {
  position: fixed;
  bottom: 50px;
  right: 50px;
  width: 50px;
  height: 50px;
  cursor: pointer;
  z-index: 1000000;
  display: none;
}

#Home .active {
  display: block !important;
}
