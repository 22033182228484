#Header{
    position: absolute;
    width: 100%;
    z-index: 10;
    height: .8rem;
    z-index: 10000;
    font-family: NTLight;
    top:0;
}

#Header .web_header_contents{
    display: flex;
    align-items: center;
    height: 100%;
    margin: 0 auto;
}

#Header .web_header_contents > div {
    font-size: 0.16rem;
    color:#fff;
    cursor: pointer;
}

#Header .web_header_contents .logo a > img{
    width: .43rem;
    height: .43rem;
}

#Header .row{
    display: flex;
    align-items: flex-end;
    height: 100%;
}

#Header #right,
#Header #left{
    width: 60px;
}

/*  Language*/
#Header .language{
    display: flex;
    justify-content: space-between;
    position: relative;
}

#Header .language .lang{
  display: flex;
  align-items: flex-end;
  font-family: "NTBold";
}

#Header .language .lang > div {
  width:20px;
  display: flex;
  align-items: flex-end;
  margin-right: 8px;
}

#Header .language .hover-box {
  width: 180px;
  display: none;
  background: #fff;
  position: absolute;
  right: 0;
  bottom: -56px;
  border: 1px solid #d5d6dc;
  padding: 0 !important;
  z-index: 10001;
}

#Header .language .lang:hover + .hover-box {
  display: block;
}

#Header .language .hover-box:hover {
  display: block;
}

#Header .language .hover-box > ul {
  width: 100%;
}

#Header .language .hover-box > ul li {
  display: flex;
  align-items: center;
  padding: 0 !important;
  width: 100%;
  cursor: pointer;
  padding: 5px 0px !important;
  border-top: 1px solid #f1f1f4;
}

#Header .language .hover-box > ul li:hover {
  background-color: #f4f5f8;
}

#Header .language .hover-box > ul li span {
  margin-left: 10px;
  font-weight: bold;
  color: #2b2b2b;
}