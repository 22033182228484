#MHeader{
    width: 100%;
    z-index: 10;
    height: 50px;
    font-family: NTLight;
    z-index: 100000000;
    background: #fff;
    position: fixed;
    top:0;
}

/*  Language*/
#MHeader .language{
    display: flex;
    justify-content: space-between;
    position: relative;
}

#MHeader .language .lang{
  display: flex;
  align-items: flex-end;
  font-family: "NTBold";
}

#MHeader .language .lang > div {
  width:20px;
  display: flex;
  align-items: flex-end;
  margin-right: 8px;
}

#MHeader .language .hover-box {
  width: 180px;
  display: none;
  background: #fff;
  position: absolute;
  right: 0;
  bottom: -56px;
  border: 1px solid #d5d6dc;
  padding: 0 !important;
  z-index: 10001;
}

#MHeader .language .lang:hover + .hover-box {
  display: block;
}

#MHeader .language .hover-box:hover {
  display: block;
}

#MHeader .language .hover-box > ul {
  width: 100%;
}

#MHeader .language .hover-box > ul li {
  display: flex;
  align-items: center;
  padding: 0 !important;
  width: 100%;
  cursor: pointer;
  padding: 5px 0px !important;
  border-top: 1px solid #f1f1f4;
}

#MHeader .language .hover-box > ul li:hover {
  background-color: #f4f5f8;
}

#MHeader .language .hover-box > ul li span {
  margin-left: 10px;
  font-weight: bold;
  color: #2b2b2b;
}

#MHeader .mobile_header_contents{
  display: block;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#MHeader .mobile_header_contents > div {
  font-size: 0.16rem;
  cursor: pointer;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  padding-right: 10px;
}
#MHeader .mobile_header_contents div > a{
  display: flex;
  align-items: center;
  justify-content: center;
}

#MHeader .mobile_header_contents div a > img{
    width:70%;
}

/* burger */
#MHeader .burger_menu {
  position: relative;
  width: 25px;
  height: 25px;
  cursor: pointer;
  float: left;
}

#MHeader .burger_menu span {
  background-color: #000;
  display: block;
  height: 1px;
  position: absolute;
  -webkit-transform-origin: center;
  transform-origin: center;
  transition-duration: 86ms;
  transition-property: background-color, opacity, -webkit-transform;
  transition-property: background-color, opacity, transform;
  transition-property: background-color, opacity, transform, -webkit-transform;
  transition-timing-function: ease-out;
  width: 25px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  z-index: 10000;
}

#MHeader .burger_menu.is-active span {
  background-color: #030f26;
}

#MHeader .burger_menu span:nth-child(1) {
  top: -18px;
}

#MHeader .burger_menu.is-active span:nth-child(1) {
  -webkit-transform: translateY(6px) rotate(-45deg);
  transform: translateY(9px) rotate(-45deg);
}

#MHeader .burger_menu span:nth-child(2) {
  top: 18px;
}

#MHeader .burger_menu span:nth-child(3) {
  width: 20px;
  left: 3px;
}

#MHeader .burger_menu.is-active span:nth-child(3) {
  -webkit-transform: translateY(0px) rotate(45deg);
  transform: translateY(0px) rotate(45deg);
  width: 25px;
  left: 0px;
}

#MHeader .burger_menu.is-active span:nth-child(2) {
  opacity: 0;
}

#MHeader .side-menu {
  position: absolute;
  top: 70px;
  left: -120vw;
  width: 50vw;
  height: 100vh;
  font-size: 20px;
  transition: 0.5s;
  transition: height 0s ease;
}

#MHeader .side-menu > .side-menu-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50%;
  padding-top: 120px;
}

#MHeader .side-menu > .side-menu-wrap ul li {
  height: 50px;
  text-align: center;
  padding-left: 20px;
  line-height: 3;
  user-select: none;
}

#MHeader .side-menu > .information ul li {
  font-size: 15px;
}

#MHeader .side-menu-active {
  position: fixed;
  top: 70px;
  left: 0;
  width: 100vw;
  height: 94vh;
  background: #fff;
  font-size: 20px;
  transition: 0.5s;
  display: flex;
  align-items: center;
  background-image: url(/img/asset/figure_2.png);
  background-position: 99% 100px;
}

#MHeader .side-menu-active > .side-menu-wrap {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;
  width: 100%;
  height: 80%;
  z-index: 9999;
  position: relative;
}

#MHeader .side-menu-active > .side-menu-wrap ul li {
  height: 35px;
  text-align: center;
  user-select: none;
  color: #000;
}

#MHeader .side-menu-active ul li:hover {
  text-decoration: underline;
  cursor: normal;
}

#MHeader .side-menu,
#MHeader .side-menu-active {
  top: 50px;
}

#MHeader .side-menu-active > .side-menu-wrap .go_contact_wrap{
  border:1px solid #b6ebe7;
  box-sizing: border-box;
  padding: 20px;
  font-family: "NTBold";
  color: #2d495f;
}

#MHeader .side-menu-active > .side-menu-wrap .go_contact_wrap > div:nth-child(1) {
  padding : 10px 0 30px 0;
  font-size: .3rem;
}

#MHeader .side-menu-active > .side-menu-wrap .go_contact_wrap > div:nth-child(2) {
  font-family: "NTLight";
  font-size: .25rem;
  line-height: 1.5;
}

#MHeader .side-menu-active > .side-menu-wrap .go_contact_wrap > div:nth-child(3) > a {
  font-family: "NTLight";
  font-size: .25rem;
  color: #0ac1a7;
  padding: 20px 0 10px 0;
  justify-content: flex-start;
  cursor: pointer;
}