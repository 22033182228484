#Main {
  font-size: 0.7rem;
  width: 100%;
  z-index: -1;
  font-family: "NTThin";
  height: 100%;
}

#Main .main_content {
  width: calc(100% - 125px);
  height: 100%;
  margin: 0 auto;
  position: absolute;
  padding: 0.8rem 0;
  z-index: 10;
  top: 0;
}

#Main .main_content .main_title_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #fff;
  font-size: 0.4rem;
}

#Main .main_content .main_title_wrap .title_top {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* height: 70%; */
}

#Main .main_content .main_title_wrap .title_top::before {
  width: 20px;
  content: "";
  background-image: url(/img/asset/left-quote.png);
  position: absolute;
  left: 65px;
  top: 0;
  height: 20px;
  background-size: 100%;
  background-repeat: no-repeat;
}

#Main .main_content .main_title_wrap .title_top::after {
  width: 20px;
  content: "";
  background-image: url(/img/asset/right-quote.png);
  position: absolute;
  right: 65px;
  top: 0;
  height: 20px;
  background-size: 100%;
  background-repeat: no-repeat;
}

#Main .main_content .main_title_wrap .title_top div:nth-child(2) {
  font-family: "NTBold";
  font-size: 0.45rem;
  padding: 0.45rem 0 0.7rem 0;
}

#Main .main_content .main_title_wrap .title_top .box_line {
  border: 5px solid #fff;
  width: 2.5rem;
  height: 0.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

#Main .main_content .main_title_wrap .title_top .box_line > img {
  width: 90%;
  height: 90%;
}

#Main .main_content .main_title_wrap .title_bottom {
  height: 20%;
  font-family: "NTLight";
  font-size: 0.18rem;
  opacity: 0.5;
  padding-top: 100px;
}

#Main .swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

#Main .swiper-slide {
  width: 100% !important;
}

#Main #Grid .line {
  opacity: 0.2;
}

#Main #Right,
#Main #Left {
  position: absolute;
  top: 0;
  right: 0;
  border-left: 1px solid #d7d7d7;
  z-index: 1;
  opacity: 0.2;
}

#Main #Left {
  border: none;
  border-right: 1px solid #d7d7d7;
}

@media screen and (max-width: 1100px) {
  #Main .main_content {
    padding: 0;
  }

  #Main .main_content .main_title_wrap {
    font-size: 0.3rem;
  }

  #Main .main_content .main_title_wrap .title_top div:nth-child(2) {
    font-size: 0.35rem;
  }

  #Main .main_content .main_title_wrap .title_top .box_line {
    width: 2rem;
    height: 0.6rem;
  }
}
