#MSwiper{
  height: 100%;
}

#MSwiper .swiper-container{
  width: 100%;
  height: 100%;
}

#MSwiper .swiper-slide {
  height: auto;
  font-size: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  /* width: 100% !important; */
}

#MSwiper .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#MSwiper .swiper-slide:nth-child(2n) {
  width: 60%;
}

#MSwiper .swiper-slide:nth-child(3n) {
  width: 40%;
}

#MSwiper .row_1{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

#MSwiper .row_3{
  width: 100%;
  height: 100%;
}

#MSwiper .count{
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  font-size: 2rem;
  font-family: "NTBold";
  height: 100%;
  opacity: .2;
  position: absolute;
  right:0;
}

#MSwiper .row_1 .title{
  color: #1377ff;
  font-family: "NTBold";
  padding: 20px 0;
  font-size: 0.25rem;
  word-wrap: normal;
}

#MSwiper .row_1 .explanation{
  line-height: 1.5;
  font-size: 0.22rem;
}

#MSwiper .row_1 .explorer{
  color: #00ffeb;
}

#MSwiper .row_1 .iconex,
#MSwiper .row_1 .flexmall{
  color:#fff;
}

#MSwiper .row_1 .iconex > span{
  color:#ffde3f;
}

#MSwiper .row_1 .flexmall > span{
  color:#d5cd77;
}

#MSwiper .row_1 .jobus > img{
  width: 20%;
}

#MSwiper .project_title{
  padding: .9rem 0;
}

#MSwiper .swiper-button-prev,
#MSwiper .swiper-button-next{
  color:#fff;
  top: 40%;
}